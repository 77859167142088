import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularResizeElementDirection, AngularResizeElementEvent } from 'angular-resize-element';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { FilterService } from 'src/app/services/filter.service';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-annotation-view',
  templateUrl: './annotation-view.component.html',
  styleUrls: ['./annotation-view.component.scss']
})
export class AnnotationViewComponent implements OnInit, OnChanges {

  @Input("offcanvasRightAnnotationNotes") offcanvasRightAnnotationNotes:any;
  @Input("annotationIndication") annotationIndication:any;
  
  @Output("onResizeSideWindow") onResizeSideWindow = new EventEmitter<any>();

  reqSubcription: Subscription[] = [];
  decryptedParams:any;
  clientKey:any;
  moduleKey:any;
  dashboardKey:any;

  // annotation Notes...
  searchAnnotationText='';
  annotationData:any;
  showAll=false;
  moment = moment;
  employee_key: any;
  loadAnnotationApi = false;
  public readonly AngularResizeElementDirection = AngularResizeElementDirection;
  public data: any = {};
  
  constructor(
    private route: Router,
    private router: ActivatedRoute,
    private navigationService: NavigationService,
    private filterService: FilterService
  ) {
    this.router.params.subscribe((p: any) => {
      const empkey: any = sessionStorage.getItem("employee_key");
      this.employee_key = JSON.parse(empkey);

      // Query Params
      this.router.queryParams.subscribe((params:any) => {
        this.decryptedParams = this.navigationService.decryptData(params);
        this.clientKey = this.decryptedParams["cl_key"];
        this.moduleKey = this.decryptedParams["md_key"];
        this.dashboardKey = this.decryptedParams["ds_key"];
      })
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['annotationIndication'].currentValue != changes['annotationIndication'].previousValue) {
      this.getcommentsData();
    }
  }

  ngOnInit(): void {
    this.checkOrientation();
  }

  // annotation Notes
  trackByFnAnnotation(index:any) {
    return index;
  }

  editedChanges(updatedDate: any) {
    if (updatedDate == null) { return false } else {
      let start_Date: any = moment(updatedDate);
      let end_Date: any = moment(new Date());
      let totalDays: any = end_Date.diff(start_Date, 'days');
      if (totalDays <= 2) {

        return true
      } else {
        return false
      }
    }
  }

  getcommentsData() {
    if(this.filterService.baseQuery==null) return;
    if(this.filterService.isEmptyObject(this.filterService.baseQuery)) return;

    let postdata: any = { report_typ: "A", comment_typ: null, api_key: '100132'};
    this.reqSubcription.push(this.filterService.commentsQuery(postdata).subscribe((res: any) => {  
      let messages = res ? res : [];
      if(messages.length>0) {
        this.annotationData = [...messages].sort((a: any, b: any) => {
          const momentA = moment(a.insert_timestamp);
          const momentB = moment(b.insert_timestamp);
          return momentB.diff(momentA);
        });
      } else {
        this.annotationData = [];
      }
      this.loadAnnotationApi = true;
    }, (err:any) => {
      this.annotationData = [];
      this.loadAnnotationApi = true;
    }));
  }

  public onResizeSide(evt: AngularResizeElementEvent): void {
    this.data.width = evt.currentWidthValue;
    this.data.height = evt.currentHeightValue;
    this.data.top = evt.currentTopValue;
    this.data.left = evt.currentLeftValue;
    this.onResizeSideWindow.emit(this.data);
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: Event) {
    this.checkOrientation();
  }

  checkOrientation() {
    this.data.width = 320;
    this.onResizeSideWindow.emit(this.data);
  }

  ngOnDestroy(): void {
    this.reqSubcription.forEach(res => res.unsubscribe())
  }

}
