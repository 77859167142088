

<div #WordCloud style="font-family: Poppins !important">
  <main>
    <article #fs fullscreen-able [class.is-active]="isActive" style="background-color: white;">
      <div style="background-color: #ffff;">
        <icon-chart 
          [headerConfig]="headerConfig" 
          [iconList]="iconList" 
          [data]="data" 
          [pageKey]="pageKey" 
          [heading]="heading"
          chartName="wordCloud" 
          [config]="wordCloudContainer" 
          [expand]="fs" 
          [fullscreen]="isFullscreen"
          [item]="item"
        ></icon-chart>
      </div>
     
      <div><loading *ngIf="isLoading"> </loading></div> 

      <div #WordCloudChart>
        <div [style.display]="noData?'none':''">
          <div  #wordCloudContainer  style="margin: 0; padding: 0;"></div>
          <div id="HbarChartDiv" style="margin: 0; padding: 0; overflow-y: auto"></div>
        </div>

        <div style="height: 500px; width: auto; " *ngIf="noData">
          <span class="bg-text" >There is no data for this time period</span>
        </div>

        <div id="d3WordCloudTooltip">
          <div *ngIf="(tooltipType=='groupFullName')"   style="background-color: #c9c9c9;padding: 3px;border-radius: 7px; text-transform :capitalize ;font-size: 12px;"> {{mytooltipData}}</div>
          <div class="container chart_tooltip" style="min-height: 50px; height: auto;" *ngIf="tooltipType=='groupHBar' && tooltip">
            <div class="row chart_tooltip_header">
              <div class="col-12 d-flex flex-column">
                <div class="chart_tooltip_header_heading mt-1 mb-1">
                  {{ this.item.config?.y_axis_nav?.value=='true' ? mytooltipData[0].name + ' Patients' : heading }}
                </div>
              </div>
            </div>
            
            <div class="mt-2 mb-5 pb-1" *ngIf="height > 200" [ngClass]="(dataTurn < 250) ? 'chart_tooltip_body-after' :'chart_tooltip_body'">
              <div class=" col-12 d-flex m-auto align-items-center pl-0" *ngFor="let data of mytooltipData">
                <div>
                  <div class="tooltip_outlay" [style.background]="data.fill" ></div>
                </div>  
                <div class="chart_tooltip_body_data ml-2"> {{ this.item.config?.stacked_bar?.value=='true' ? data?.key: data?.name }} : {{data?.value ? numbedPipe(data?.value) : 0}}</div>
              </div> 
            </div> 

            <div class="mt-2 mb-5 pb-1" *ngIf="height < 200" [ngClass]="(dataTurn < 250) ? 'chart_tooltip_body-after100' :'chart_tooltip_body100'">
              <div class=" col-12 d-flex m-auto align-items-center pl-0" *ngFor="let data of mytooltipData">
                <div>
                  <div class="tooltip_outlay" [style.background]="data.fill" ></div>
                </div>
                <div class="chart_tooltip_body_data ml-2"> {{ this.item.config?.stacked_bar?.value=='true' ? data?.key: data?.name }} : {{data?.value ? numbedPipe(data?.value) : 0}}</div>
              </div> 
            </div>
            
          </div>
        </div> 
      </div>
    </article>
  </main>
</div>