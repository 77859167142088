import { Component } from '@angular/core'
import { CommonModule } from '@angular/common'
import { DeviceDetectionService } from 'src/app/services/detectipad.service'
import Widget from '../widget'

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'app-text-notes',
  template: `
  <span *ngIf="noteValue" class="widgetContainer" [style.margin.px]="!this.deviceDetectionService.isDesktop ? '10' : '0'">
    <pre *ngIf="historyFlag!='H'" class="preTag" > {{ noteValue || '' }}</pre>
    <pre *ngIf="historyFlag=='H'" class="preTag" > {{ "The data shown is from " + (differenceInDays ? differenceInDays : 0) + " days from the current date" }}</pre>
  </span>
  `,
  styleUrls: ['./text-notes.component.scss']
})
export class WidgetTextNotesComponent extends Widget {
  static override key = 100201
  
  historyFlag:any;
  noteValue:any;
  differenceInDays:any;
  
  constructor (
    public deviceDetectionService: DeviceDetectionService
  ) {
    super();
    this.subs.push(
      this.route.params.subscribe((p: any) => {
        this.route.queryParams.subscribe((params:any) => {
          let decryptedParams = this.navigationService.decryptData(params);
          this.historyFlag = decryptedParams?.["hist_flg"] || undefined;
        })
      })
    )
  }

  override ngOnInit(): void {
    this.subs.push(
      this.filterService.filterQuery.subscribe((res:any)=> {
        this.loadConfig();
        let filterPayload:any = this.filterService.baseQuery
        const startDate:any    =  new Date(filterPayload.from_date);
        const endDate:any      =  new Date(filterPayload.to_date);
        const differenceInMs   =  endDate - startDate;
        this.differenceInDays = ( differenceInMs / (1000 * 60 * 60 * 24))+1;
      })
    );    
  }

  loadConfig() {
    let item = this.item.config?.['text_note_config']
    if(item?.['selectedValue'] == "payment calculator") {
      if(this.enhancementService.currentQuarterFlag) {
        this.noteValue = item?.currentNotes;
      } else {
        this.noteValue = item?.previousNotes;
      }
    } else {
      this.noteValue = item?.defaultNotes || this.item.config?.['notes'] || '';
    }
  }
}