import { FilterService } from "src/app/services/filter.service";
import { MixpanelService } from 'src/app/services/mixpanel.service';
import { Component, OnInit, ViewChild, OnDestroy, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as _ from "lodash";
import { MatMenuTrigger } from "@angular/material/menu";
import { DashboardService } from "src/app/services/dashboard.service";
import { SessionService } from "src/app/services/session.service";
import { debounceTime, Subscription } from "rxjs";
import { UserActivityService } from "src/app/services/useractivity.service";
import { DeviceDetectionService } from "src/app/services/detectipad.service";
import { Location } from "@angular/common";
import moment from "moment";
import { WidgetService } from "src/app/services/widget.service";
import { NavigationService } from "src/app/services/navigation.service";
import { EnhancementService } from "src/app/services/enhancements-service";

@Component({
  selector: "app-pages",
  templateUrl: "./pages.component.html",
  styleUrls: ["./pages.component.scss"]
})
export class PagesComponent implements OnInit, OnDestroy {
  pagename: any;
  version: any;
  reloadPage = false;
  moment = moment;
  reqSubcription: Subscription[] = [];
  userName: string = "";
  moduleKey: any;
  clientKey: any;
  dashboardKey: any;
  dashboardPages: any = [];
  pages: any;
  selectedTabIndex = 0;
  singlePageFlag = false;
  @Input("widget") widget: any;

  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger!: MatMenuTrigger;
  info: any;
  logoImage: any;
  colorCodes: any;
  colors: any;
  selectConfig: any;
  sourceName: any;
  message: any;
  cardName: any;
  cardId: any;
  newColor: any;
  clientLogo: any;
  prName = "";
  selectedType: any;
  configTab = '4';
  showAlerts: boolean = true;
  refreshTheDashboard = false;
  decryptedParams:any;

  constructor(
    public route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private sessionService: SessionService,
    public dashboardService: DashboardService,
    public filterService: FilterService,
    protected widgetService: WidgetService,
    public deviceDetectionService: DeviceDetectionService,
    private mixpanelService: MixpanelService,
    private navigationService: NavigationService,
    private enhancementService: EnhancementService,
  ) {
   
    // Route
    this.route.params.subscribe((p: any) => {
      this.cardName = p["cardName"] || '';
    
      // Query Params
      this.route.queryParams.subscribe((params:any) => {
        this.decryptedParams = this.navigationService.decryptData(params);
        this.clientKey = this.decryptedParams["cl_key"];
        this.moduleKey = this.decryptedParams["md_key"];
        this.dashboardKey = this.decryptedParams["ds_key"];
        this.filterService.dashboardKey = this.decryptedParams["ds_key"];
        
        // Tab Level Redirecting
        let tabId = params["tab_id"];
        this.selectedTabIndex = Number(tabId);
      });
      
      // Prescriber Name Header
      if(this.cardName?.toString().replace(/\s+/g, '').toLowerCase() == 'prescribercard') {
        let prName: any = sessionStorage.getItem("navbar_prName");
        this.prName = prName || '';
      } else {
        this.prName = '';
      }
      
      this.pages=[];

      this.selectConfig = sessionStorage.getItem("selectConfig");
      if (!this.selectConfig) this.selectConfig = "S3";
      
      // for user menu icons color css classes.
      this.dashboardService.moduleKey = this.moduleKey;
      
      // patient list Hover Functionality
      this.filterService.hoverBackground = false;

      // filter data is empty when its onload 
      this.filterService.previousFilterModel = {}
      this.userName = this.sessionService.getUsername();
      this.newColor = this.sessionService.getColorCodes();
      this.clientLogo = this.sessionService.getClintLogo();

      this.loadPageConfig();
      this.getPageLogo();

      this.filterService.logoChange.subscribe((res: any) => {
        this.clientLogo = res.logo
        this.newColor = res.colour_codes?.length == 1 ? res.colour_codes.toString() : "'linear-gradient(to right," + res.colour_codes?.toString() + ")'";
      })
    });
  }


  ngOnInit(): void {
    // in the filter when page load it should be empty - key and name 
    this.filterService.duplicateParameter = {};
    this.filterService.locationPresId = ''
  }

  styleLogo(styleLogo: any) {
    return styleLogo;
  }

  // Get Page Logo
  getPageLogo() {
    let req = {
      username: this.userName,
      client_key: this.clientKey,
      module_key: this.moduleKey,
    };
    this.reqSubcription.push(
      this.dashboardService.getDashboardAccess(req).pipe(debounceTime(500)).subscribe((res) => {
        this.info = res ? res : [];

        this.info.forEach((row: any) => {
          if (row.logo_image) {
            this.logoImage = row?.logo_image;
          }
          if (row.color_code) {
            this.colorCodes = row?.color_code;
          }
        });

        this.enhancementService.sendAllPageInfoData(this.info);
        
      }, err => {
        console.log(err)
      })
    );
  }

  // Get Page Access from LoadPageConfig
  loadPageConfig() {
    this.reloadPage = false;
    let req: any = {};
    let body = {
      username: this.userName,
      client_key: this.clientKey,
      dashboard_key: this.dashboardKey,
    };

    const navDetailInfoString = sessionStorage.getItem("navDetailInfo");

    // debugger

    if (navDetailInfoString && this.cardName) {
      let routerPages = JSON.parse(navDetailInfoString);
      req = {};

      if (this.selectConfig == "S3") {
        req['bucketName'] = routerPages['s3JSON'].s3_bucket_nm;
        req['filePath'] = routerPages['s3JSON'].s3_file_path;

        if (routerPages['isDashboardLevel'].toString().toLowerCase() == 'yes') {
          body['dashboard_key'] = routerPages['dbJSON'].work_key;
          this.getPageAccess(body);
        } else {
          this.loadJsonToStringify(req);
        }
      } else {
        req['work_key'] = routerPages['dbJSON'].work_key;
        req['version_number'] = routerPages['dbJSON'].version_number;
        req['ConfigType'] = "DB";
        this.loadJsonToStringify(req);
      }
      debugger
      let navName:any='';
      if(routerPages['isNavSelected']){
        const value:any = JSON.parse(sessionStorage.getItem("navHeaderData") || '');
        let arr = routerPages['navpageName'].split(",");
        arr.forEach((m:any)=> {
          navName  +=" " + value[m];
      });
      }
      this.pagename =  routerPages['isNavSelected'] ? (routerPages['navpageNameLeft'] + navName + routerPages['navpageNameRight']) : (routerPages['pageName'] || '')

    } else {
      if (this.selectConfig == "S3") {
        this.getPageAccess(body);
      } else {
        this.getPageDetails(body);
      }
    }
  }

  getPageAccess(body: any) {
    this.reqSubcription.push(
      this.dashboardService.getPageAccess(body).subscribe((dashboard) => {
        if (dashboard.length) {
          this.getPageDetails(dashboard);
        } else {
          if (!this.userName) {
            window.location.href = "/login";
          }
        }
      })
    );
  }

  // Get Page Details
  getPageDetails(pages: any) {
    if (this.selectConfig == "S3") {
      pages.forEach((data: any) => {
        let body = {
          bucketName: data.s3_bucket,
          filePath: data.file_path + "/" + data.file_nm
        };
        this.loadJsonToStringify(body);
      });
    } else {
      let body = {
        work_key: pages.dashboard_key,
        version_number: 1.0,
        ConfigType: "DB",
      };
      this.loadJsonToStringify(body);
    }
  }

  // Load Json to Stringify
  loadJsonToStringify(page: any) {
    this.reqSubcription.push(
      this.dashboardService.getPageDetails(page).subscribe((response: any) => {
  
        if (page.ConfigType == "DB") {
          response.forEach((data: any) => {
            this.pages.push(this.parsetheStringifyPages(data.LeafElement || []));
          });
        } else {
          this.pages.push(this.parsetheStringifyPages(response[0].LeafElement || []));
        }
        
        // debugger

        this.dashboardPages = [];
        this.pages.map((m:any)=> JSON.stringify( m.page_config_details))
        this.dashboardPages = _.cloneDeep(this.pages);
        this.dashboardPages.map((m:any)=> {
          m.page_config_details = JSON.stringify(m.page_config_details);
        })
        this.dashboardPages = _.sortBy(this.dashboardPages, "page_seq_no");
        this.dashboardService.getPageKey = _.map(this.dashboardPages, 'page_key');
        this.dashboardService.dashboardKey = response[0]?.dashboard_key;
        if (this.dashboardPages.length > 1) this.filterService.singlePage = false;
        else this.filterService.singlePage = true;


        // This will track the User Event in Mix Panel site
        this.mixpanelService.track(this.dashboardPages[0].page_nm);
        this.selectedTabName = this.dashboardPages[this.selectedTabIndex]?.page_nm;

        // Configurations
        this.refreshIcon();
        this.configurationOfSelectedTab();

        setTimeout(() => {
          this.reloadPage = true;
        }, 1500);

      }, (err) => {
        console.log("Get Page detail", err);
      })
    )
  }

  parsetheStringifyPages(pages: any) {
    for (let idx in pages) {
      if (
        pages[idx].page_config_details == "[]" ||
        pages[idx].page_config_details == ""
      ) {
        pages[idx].page_config_details = [];
      } else {
        pages[idx].page_config_details = JSON.parse(
          pages[idx].page_config_details
        );
      }
    }
    return pages[0];
  }

  // Selected Tab'
  selectedTabName: any = ''
  seletedTab(event: any, dashboardPages: any) {
    // debugger

    // filter data is empty when its onload 
    this.filterService.previousFilterModel = {}

    this.selectedTabName = dashboardPages[event].page_nm
    let selectedTab = this.dashboardPages[event];
    this.selectedTabIndex = parseInt(event);
    this.mixpanelService.track(selectedTab.page_nm);


    // Tracking Tabs for Payment Calc Record Tab;
    if(this.decryptedParams['tab_id']!=this.selectedTabIndex) {
      if(this.decryptedParams.hasOwnProperty('record')) {
        this.decryptedParams['record'] = 'no';
      }
    }
    
    delete this.decryptedParams['tab_id'];
    const newCryptedObj = Object.assign({}, { tab_id: this.selectedTabIndex }, this.decryptedParams);
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.navigationService.encrypedData(newCryptedObj),
      queryParamsHandling: 'merge' // Merge with existing query params
    });
  }

  clickTabChange() {
    // sessionStorage.removeItem('window.colState');
  }

  clickPrescriberList() {
    this.location.back()
  }

  showNotifications() {
    this.showAlerts = false;
  }

  configurationOfSelectedTab() {
    let selectedTab = this.pages?.[0].page_config_details.find((r: any) => r.key == 333333 && r.tabSelection)
    this.configTab = selectedTab ? selectedTab.tabSelection : 4
  }

  refreshIcon() {
    const key = this.pages.find((m: any) => m.page_config_details.find((o: any) => (o.key == 111111 && o.refresh)))
    this.refreshTheDashboard = key ? true : false;
  }

  // Destroy All Subscription
  ngOnDestroy(): void {
    this.reqSubcription.forEach((res) => res.unsubscribe());
  }
}
