<!-- Resize  -->
<div 
    class="resize resize__left" 
    (resize)="onResizeSide($event)" 
    [targetElement]="offcanvasRightAnnotationNotes"
    [direction]="AngularResizeElementDirection.LEFT">
</div>

<!-- Header Name -->
<h2 class="px-2 pt-2 mb-0 selectedHeaderName">Annotation</h2>

<hr>

<!-- Annotation Block -->
<div>
    <div class="d-flex align-items-center justify-content-around">
        <div>
            <span style="color: #8d8b8b;"> Show </span>
        </div>
        <div class="d-flex justify-content-center align-items-center" style="min-width: 200px;">
            <span [style.font-weight]="this.showAll ? 'normal' : 'bold'">All</span>
            <mat-slide-toggle class="example-margin slidertext sliderIconGLobal"[(ngModel)]="showAll"></mat-slide-toggle>
            <span [style.font-weight]="this.showAll ? 'bold' : 'normal'">Current Dashboard</span>
        </div>
    </div>

    <div class="m-2" style="width: 300px;text-align: right;">
        <input type="search" class="form-control" id="exampleInputSearch1" [(ngModel)]="searchAnnotationText" aria-describedby="searchHelp" placeholder="Search Annotations" style="font-size: 14px;font-family: 'Poppins';">
    </div>

    <hr>

    <div *ngIf="(annotationData | searchText: searchAnnotationText)?.length > 0; else noResults">
        <cdk-virtual-scroll-viewport class="scrollForAnnotation" itemSize="10" style="overflow-y: auto;height: calc(100vh - 153px);font-family: 'Poppins' !important;">
            <ng-container *cdkVirtualFor="let message of annotationData | searchText: searchAnnotationText; let i = index;trackBy: trackByFnAnnotation;"> 
            <div class="px-2">
                <div class="row my-2">
                <div class="d-flex justify-content-between align-items-center">
                    <div>
                    <span class="mr-3" style="color: #8d8b8b;font-size: 12px;">{{ '# '+ (i+1) }}</span>
                    <span style="color: #8d8b8b;font-size: 12px;">{{ moment(message['insert_timestamp']).fromNow() }}</span>
                    </div>
                </div>
                </div>

                <div class="row">
                <div class="d-flex align-items-center">
                    <div class="mr-2"><img class="imageCss imageCss1" src="assets/man.png" alt="Profile"></div>
                    <div>
                    <span class="card-title fontForHeader">
                        <span style="font-family: 'Poppins'!important;font-size: 14px;">{{ message.employee_name || "No UserName"}}</span>
                        <span *ngIf="employee_key._value == message.employee_key" style="color: blue;">(You)</span>
                        <span *ngIf="editedChanges(message.update_timestamp)" style="color: rgb(239, 170, 97); font-size: 8px; vertical-align: text-bottom;">{{' Edited'}}</span>
                    </span>
                    <!-- <span class="card-subtitle fontForHeader">{{ (message.update_timestamp==null) ? getCurrentDate(message.insert_timestamp):getCurrentDate(message.update_timestamp) }}</span> -->
                    </div>
                </div>
                </div>

                <div class="row mt-3">
                <p class="card-text mx-1">{{ message.comments }}</p>
                </div>

                <!-- <div class="row my-3">
                <span style="font-size: 13px;">
                    <span style="color: #8d8b8b;">Data from:</span>
                    <span style="font-weight: 600;"> {{ moment(message['period']).format("MM/DD/YYYY") }}</span>
                </span>
                </div> -->

                <div class="row my-3">
                <div class="d-flex align-items-center" style="flex-flow: wrap;">
                    <span class="chipsTagAno" *ngIf="message['brand_name']">{{message['brand_name']}}</span>
                    <span class="chipsTagAno" *ngIf="message['source_type']">{{message['source_type']}}</span>
                    <span class="chipsTagAno" *ngIf="message['source_name']">{{message['source_name']}}</span>
                    <span class="chipsTagAno" *ngIf="message['sp_location_name']">{{message['sp_location_name']}}</span>
                    <span class="chipsTagAno" *ngIf="message['kpi_name']">{{message['kpi_name']}}</span>
                    <span class="chipsTagAno" *ngIf="message['time_cycle_nm']">{{message['time_cycle_nm']}}</span>
                    <span class="chipsTagAno" *ngIf="message['insert_timestamp']">{{ moment(message['insert_timestamp']).format("MM/DD/YYYY") }}</span>
                </div>
                </div>

                <div class="mb-4" *ngIf="message?.dashboard_name">
                <span class="mx-1 chipsTagAno" style="display: inline;">{{ message.dashboard_name }}</span>
                </div>

                <hr>

            </div>
            </ng-container>
        </cdk-virtual-scroll-viewport>
    </div>
</div>

<ng-template #noResults>
    <div class="no-results" style="text-align: center;margin-top: 100px;">
        No data found.
    </div>
</ng-template>

<!-- Resize  -->
<div 
    class="resize resize__bottom--left" 
    (resize)="onResizeSide($event)" 
    [targetElement]="offcanvasRightAnnotationNotes"
    [direction]="AngularResizeElementDirection.BOTTOM_LEFT">
</div>