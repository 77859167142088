import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularResizeElementDirection, AngularResizeElementEvent } from 'angular-resize-element';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { FilterService } from 'src/app/services/filter.service';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-alert-view',
  templateUrl: './alert-view.component.html',
  styleUrls: ['./alert-view.component.scss']
})
export class AlertViewComponent implements OnInit, OnChanges {

  @Input("offcanvasRightAlertView") offcanvasRightAlertView:any;
  @Input("alertIndication") alertIndication:any;
  
  @Output("onResizeSideWindow") onResizeSideWindow = new EventEmitter<any>();
  
  public readonly AngularResizeElementDirection = AngularResizeElementDirection;
  public data: any = {};
  reqSubcription: Subscription[] = [];
  moment = moment;
  searchAlertText:any;
  alert_notification:any;

  constructor(
    private route: Router,
    private router: ActivatedRoute,
    private navigationService: NavigationService,
    private filterService: FilterService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['alertIndication'].currentValue != changes['alertIndication'].previousValue) {
      this.getAlertsNotificationData();
    }
  }

  ngOnInit(): void {
  }

  getAlertsNotificationData() {
    if(this.filterService.baseQuery==null) return;
    if(this.filterService.isEmptyObject(this.filterService.baseQuery)) return;

    let postdata: any = { "api_key": "100410", "report_typ": "D" };
    
    let obj = Object.assign({}, this.filterService.baseQuery, postdata);

    this.reqSubcription.push(this.filterService.alertNotificationFilter(obj).subscribe((res: any) => {
      this.alert_notification = res ? res : [];
    }, err => {
      this.alert_notification = [];
    }));
  }

  trackByFnMessage(index: number): number {
    return index;
  }

  public onResizeSide(evt: AngularResizeElementEvent): void {
    this.data.width = evt.currentWidthValue;
    this.data.height = evt.currentHeightValue;
    this.data.top = evt.currentTopValue;
    this.data.left = evt.currentLeftValue;
    this.onResizeSideWindow.emit(this.data);
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: Event) {
    this.checkOrientation();
  }

  checkOrientation() {
    this.data.width = 320;
    this.onResizeSideWindow.emit(this.data);
  }
}
