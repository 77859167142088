import { Injectable } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { GridApi } from "ag-grid-community";
import moment from "moment";
import { BehaviorSubject, EMPTY, observable, Observable, Subject } from "rxjs";
import { ApiService } from "./api.service";
import { HelperService } from "./helper.service";
import { isNull } from "lodash";

declare var window: any;

@Injectable({
  providedIn: "root",
})
export class FilterService {
  filterQuery = new Subject<any>();
  lastRefreshedDate = new Subject<any>();
  userMenuSideBar = new Subject<any>();
  inputValueOfPagenation = new Subject<any>();
  dataPartnerRefreshingGrid = new Subject<any>();
  badges: any[] = [];
  badgeChanges = new Subject<any>();
  refreshAlert = new BehaviorSubject(false)
  isDataLoaded: boolean = false
  brandKeyForLastRefreshed = new Subject<any>();
  kpiBreakDownSelectedType = new Subject<any>();
  flagUpdateOnscreen = new Subject<any>();
  topNavLocation = new Subject<any>();

  selectAllAsNull: any = [];
  previousSelectedItems: any[] = [];
  previousSingleSelectedItems: any[] = [];
  previousQuarterFromSelectedItems: any[] = [];
  previousQuarterToSelectedItems: any[] = [];

  backupAllSingleAndMulti: any = [];

  previousTimeCycleFromSelectedItem = {};
  previousTimeCycleToSelectedItem = {};

  previousToDateForTimeCycle: any[] = []

  previousToDateForMonth: any
  previousToDateForYear: any
  previousToDateForDate: any
  // showby variables
  showByUOM = new Subject<any>();
  showbyfilterQuery = new Subject<any>();
  showByChangeFilter = new Subject<any>();
  showByInGrid: any;
  showByHeader: any;
  showByAPI: boolean = false

  filterCheck = false;
  selectedBrandName:any;
  
  brandBasedTimeCycle = new Subject<any>();
  brandBasedTime = false;

  filterIcon = false;
  alertIcon = false;
  refreshData = false;
  kpi2LoadingIssue: any;
  headerTop: any;
  filterConfig = new Subject<any>();
  columnClickedOnGrid = new Subject<any>();
  filterQueryForAnnotation: any;
  brandCarry = false;
  dragDate: any = []
  yearDefault: any
  yearToDefault: any

  desectedValue: any;
  private hasMethodExecuted = false;

  selectedItem: any = {
    'single': {},
    'multi': {},
    'date': {},
    'quarter': {},
    'quarterFrom': {},
    'quarterTo': {},
    'timeCycle': {}
  }

  allOptionData: any = {
    'single': {},
    'multi': {},
    'date': {},
    'quarter': {},
    'quarterFrom': {},
    'quarterTo': {},
    'timeCycle': {}
  }
  findFilter: any

  hoverBackground = false

  brandSelectWithBackNavigation = true;

  testing: any = {}
  previousSelectedDate: any = {};
  filterValues: any;
  baseQuery: any;
  report_type: any;
  defaultReport_type: any
  previousModel: any = {};
  previousFilterModel: any = {};
  isDataLoadedCard = false
  filterFields: any[] = [];
  source_key: any = ''
  logoChange = new Subject<any>();
  duplicateParameter: any = {};

  sortingDataPatientAndPrescriber: any

  globalFilterFields: any[] = [];
  model: any = {}
  selectedFilterValue: any;
  isGlobal = false
  isGlobalSub = new Subject<any>();


  patienListAlertsSince: any
  alertsIconfromPatientCard = false

  rowData: any[] = [];
  sliderValue = 1
  patientListApi: any
  locationDataLoaded = false;
  refreshTheDashboard = false;

  prName = ''
  public gridApi!: GridApi
  timeCycleCount: any

  // toolTipDragEvent = new Subject<any>();

  formReset = new Subject<any>();
  timeCycleDate = new Subject<any>();
  resetBadges = new Subject<any>();
  resetBadgesCheck = new Subject<any>();
  isLinkColSelectConfig: any;

  hoverDataRefresh = new Subject<any>(); //
  refreshAlertDelete = new Subject<any>(); //
  refreshStatusChangeData = new Subject<any>();
  editingDataGrid = new Subject<any>();
  refreshAlertDeleteDups = new Subject<any>(); //

  badgeCheck = false;
  timeCycleDateRange: any;

  filterReset: any = {};
  toolTipAlertData: any

  startDate = new Date
  endDate = new Date

  selectedDate: any = {
    start: this.startDate,
    end: this.endDate,
  };

  dateRange: any = new FormGroup({
    start: new FormControl(this.selectedDate.start),
    end: new FormControl(this.selectedDate.end),
  });

  selectedDateRange: any

  hideToolTipe = false
  hideTools = new Subject<any>();
  selectedPageGrid = new Subject<any>();
  public hiraricalGridApi!: GridApi | any;
  allGroupsClosed = false;
  recdetailGrid = false;
  checkArray: any[] = []
  selectedBrand: any;
  selectedBrandCount: any;

  selectedColumn: any[] = []

  timecycleData: any[] = [];
  selectedType: any
  kpiDateRange: any
  dateRangeCheck = false;
  dateRangeFilterCheck = false;
  firstModuleKey: any;
  autoHeightAdjust = false;

  locationPresId: any;
  // Selected Filters
  navDataforFilters = new Subject<any>();
  emitFilterChange = new Subject<any>();
  currentData = this.emitFilterChange.asObservable();
  employKey: any

  widthForSideBar: any;
  refreshfromAccodin = false
  dataRefreshinTooltip = new Subject<any>();

  singlePage = false;
  showLessAndMore = new Subject<any>();
  isPHIFunc = new Subject<any>();
  onQuickFilter = new Subject<any>();
  onPageSizeChange = new Subject<any>();
  sortByChanged = new Subject<any>();
  changeDisplays = new Subject<any>();
  movingAverage = new Subject<any>();
  changeTimeCycle = new Subject<any>();
  followUP = new Subject<any>();
  exportXls = new Subject<any>();
  chartshowBy = new Subject<any>();
  phiChecked = true;
  showByKey: any;
  settingpopupclose = new Subject<any>();
  expendTree = new Subject<any>();
  client_key: any
  annotations: any = false
  getDataPatientJourneyDataHold = new Subject<any>();
  thresholdoldData: any;
  patientStatusCondition = false;
  filterLoadFlg = true;
  time_cycle:any;
  findSelectedAll:any = {};
  // DPM and DEM
  currentMsgOnDM: any;
  dashboardKey:any;
  pageKey:any;

  constructor(private apiService: ApiService, private helperService: HelperService) {
    // Client Key Get from Local
    var client_key: any = sessionStorage.getItem("client");
    let value: any = JSON.parse(client_key);
    if (value) {
      this.client_key = value[0]?.client.client_key || ''
    }

  }

  loadData(method: string, url: string, key: string, data: any, dashboard_key?: any, report_typ?: any, pageInfo?: any) {
    // debugger
    var client_key: any = sessionStorage.getItem("client");
    let value = JSON.parse(client_key);
    let request: any = {};
    if (method.toUpperCase() == "POST") {
      if (key.includes('.')) {
        let keys = key.split(",");
        for (let k in keys) {
          let kv = keys[k].split(".");
          let keyName = kv[kv.length - 1];
          request[keyName] = this.helperService.newGetDataByPath(data, keys[k]);
        }
      } else {
        // request[key] = value[0].client.client_key

        // Get Item For Session Storage
        let brand_key: any = sessionStorage.getItem("brandKeyForFilters");

        // Parameter Split
        let keyss = key.split(",")

        for (let k in keyss) {
          let keyName = keyss[k]
          if (keyName == 'username') {
            let value = JSON.parse(client_key);
            request[keyName] = value[0].user
          } else if (keyName == 'brand_key') {
            let value = JSON.parse(brand_key);
            request[keyName] = data ? data[keyName][keyName] ? data[keyName][keyName] : data[keyName] : value
          } else if (keyName == 'client_key') {
            let value = JSON.parse(client_key);
            request[keyName] = value[0].client.client_key
          } else if (keyName == 'user_id') {
            let value = JSON.parse(client_key);
            request[keyName] = value[0].user
          } else if (keyName == 'dashboard_key') {
            request[keyName] = dashboard_key ? dashboard_key : null
          } else if (keyName == 'report_typ') {
            if(data[keyName] && !this.brandBasedTime) {
              request[keyName] = data[keyName][keyName] || 'W';
            } else {
              request[keyName] =  report_typ || this.report_type || 'D';
            }
          } else if (keyName == 'source_nm') {
            request['source_key'] = data[keyName] ? data[keyName]['source_key'] : ''
          } else {
            request[keyName] = this.getFilter(data, key)
          }
        }

      }

      // Client Key Get from Local
      let value = JSON.parse(client_key);
      request["client_key"] = value[0].client.client_key
      request['employee_key'] = this.employKey
      if (pageInfo) Object.assign(request, { page_key: pageInfo });
      return this.apiService.post(url, request);
    } else {
      return this.apiService.get(url);
    }
  }

  loadDataNewMultiselection(method: string, endPoint: string, key: string, data?: any, dashboard_key?: any) {
    // data = Object.assign({}, this.selectedItem.multi, this.selectedItem.single);
    
    let client_key: any = sessionStorage.getItem("client");

    // Multi Select datas 
    if (method.toUpperCase() == "POST") {
      let request: any = {};
      if (key.includes('.')) {
        let keys = key.split(",");
        for (let k in keys) {
          let kv = keys[k].split(".");
          let keyName = kv[kv.length - 1];

          let dataApi = this.helperService.newGetDataByPath(data, keys[k]);
          request[keyName] = dataApi.length == 0 ? '' : dataApi;
        }
      } else {

        // Get Item For Session Storage
        let brand_key: any = sessionStorage.getItem("brandKeyForFilters");

        // Parameter Split
        let keyss = key.split(",")

        for (let k in keyss) {
          let keyName = keyss[k]
          if (keyName == 'username') {
            let value = JSON.parse(client_key);
            request[keyName] = value[0].user
          } else if (keyName == 'brand_key') {
            let value = JSON.parse(brand_key);
            request[keyName] = value
          } else if (keyName == 'client_key') {
            let value = JSON.parse(client_key);
            request[keyName] = value[0].client.client_key
          } else if (keyName == 'dashboard_key') {
            request[keyName] = dashboard_key ? dashboard_key : null
          } else {
            request[keyName] = this.getFilter(data, key)
          }
        }
      }

      // Client Key Get from Local
      let value = JSON.parse(client_key);
      request["client_key"] = value[0].client.client_key
      request['employee_key'] = this.employKey

      return this.apiService.post(endPoint, request);
    } else {
      return this.apiService.get(endPoint);
    }
  }

  loadDataMultiselection(method: string, endPoint: string, key: string, data?: any, dashboard_key?: any) {
    let client_key: any = sessionStorage.getItem("client");

    // Multi Select datas 
    if (method.toUpperCase() == "POST") {
      let request: any = {};
      if (key.includes('.')) {
        let keys = key.split(",");
        for (let k in keys) {
          let kv = keys[k].split(".");
          let keyName = kv[kv.length - 1];

          let dataApi = this.helperService.newGetDataByPath(data, keys[k]);
          request[keyName] = dataApi.length == 0 ? '' : dataApi;
        }
      } else {

        // Get Item For Session Storage
        let brand_key: any = sessionStorage.getItem("brandKeyForFilters");

        // Parameter Split
        let keyss = key.split(",")

        for (let k in keyss) {
          let keyName = keyss[k]
          if (keyName == 'username') {
            let value = JSON.parse(client_key);
            request[keyName] = value[0].user
          } else if (keyName == 'brand_key') {
            let value = JSON.parse(brand_key);
            request[keyName] = value
          } else if (keyName == 'client_key') {
            let value = JSON.parse(client_key);
            request[keyName] = value[0].client.client_key
          } else if (keyName == 'dashboard_key') {
            request[keyName] = dashboard_key ? dashboard_key : null
          } else {
            request[keyName] = this.getFilter(data, key)
          }
        }
      }

      // Client Key Get from Local
      let value = JSON.parse(client_key);
      request["client_key"] = value[0].client.client_key
      request['employee_key'] = this.employKey

      return this.apiService.post(endPoint, request);
    } else {
      return this.apiService.get(endPoint);
    }
  }

  getCycleDate(method: any, url: any, parameter: any, howLong: any, model: any, valueProp: any): Promise<any> {

    var client_key: any = sessionStorage.getItem("client");
    let request: any = {};

    if (parameter.includes('.')) {
      let keys = parameter.split(",");
      for (let k in keys) {
        let kv = keys[k].split(".");
        let keyName = kv[kv.length - 1];
        request[keyName] = model[keyName]
      }
    } else {
      // Get Item For Session Storage
      let brand_key: any = sessionStorage.getItem("brandKeyForFilters");
      // Parameter Split
      let keyss = parameter.split(",")

      for (let k in keyss) {
        let keyName = keyss[k]
        if (keyName == 'username') {
          let value = JSON.parse(client_key);
          request[keyName] = value[0].user
        } else if (keyName == 'brand_key') {
          let value = JSON.parse(brand_key);
          request[keyName] = value
        } else if (keyName == 'client_key') {
          let value = JSON.parse(client_key);
          request[keyName] = value[0].client.client_key
        } else {
          request[keyName] = this.getFilter(model, parameter)
        }
      }
    }

    // Client Key Get from Local
    let value = JSON.parse(client_key);
    request["client_key"] = value[0].client.client_key
    request['employee_key'] = this.employKey
    return new Promise((resolve, reject) => {
      if (method.toUpperCase() == "POST") {
        this.apiService.post(url, request).subscribe((res) => {
          if (res.length > 0) {

            this.endDate = moment(res[0].dp_cycle_date).toDate();
            howLong = howLong.toString()
            if (howLong.includes('brand')) {
              this.startDate = moment(res[0][valueProp]).toDate();
            } else {
              this.startDate = moment(res[0][valueProp])
                .add(-parseInt(howLong || 15), "days")
                .toDate();
            }

            this.selectedDate.start = this.startDate;
            this.selectedDate.end = this.endDate;
          }
          resolve(this.selectedDate);
        });
      } else {
        this.apiService.get(url).subscribe((res) => { });
      }
    });
  }

  setDateFromTimeCycle(data: any) {

    this.startDate = moment(data[0].from_dt).toDate();
    this.endDate = moment(data[0].to_dt).toDate();

    this.selectedDate.start = this.startDate;
    this.selectedDate.end = this.endDate;

    return this.selectedDate
  }

  setBadgeValue(key: string, value: any, length?: any, selectedItem?: any, path?: any, showBy?: any, labelProp?: any, selectedType?: any) {
    this.badges.find((b: any) => {
      if (b.key == key) {
        b.value = value;
        b.length = length;
        b.selectedData = selectedItem;
        b.path = path
        b.showBy = showBy
        b.labelProp = labelProp
        b.selectedType = selectedType == 'Q'
      }

    });
    this.badgeChanges.next(this.badges);
  }

  setFilterValue(values: any, queryParams?: any) {

    // auto query stored based on formly form data
    const query: any = {};
    for (let [key, value] of Object.entries(values)) {
      if (value instanceof String) {
        query[key] = value;
      } else if (value instanceof Date) {
        query[key] = moment(value).format("yyyy-MM-DD");
      } else if (value instanceof Object) {
        const v: any = value;
        if (v[key] == undefined) {
          let a: string = v?.map((a: any) => a[key]);
          query[key] = a.toString();
        } else {
          query[key] = v[key];
        }
      } else if (value instanceof Array) {
        query[key] = this.getFilter(value, key);
      } else if (isNull(value)) {
        query[key] = value;
      }
    }

    // brand assign to basequery for refreshed date
    var brand_key: any = sessionStorage.getItem("brandKeyForFilters");
    let brandKey = JSON.parse(brand_key);

    // Client Key Get from Local
    var client_key: any = sessionStorage.getItem("client");
    let value = JSON.parse(client_key);
    this.client_key = value[0].client.client_key || ''

    // client_key added into baseQuery and brandkey and dublicate key from filter configuration
    
    // if(this.brandBasedTime) {
      Object.assign(query, {'time_cycle': this.time_cycle});
    // }

    this.baseQuery = Object.assign(query, {
      'client_key': this.client_key,
      'dashboard_key': this.dashboardKey,
      // 'page_key': this.pageKey,
      'brand_key': query['brand_key'] ? query['brand_key'] : brandKey,
      'employee_key': this.getEmpKey() ? this.getEmpKey() : this.employKey,
    }, this.duplicateParameter);

    // if (this.showByInGrid) {
    //   this.baseQuery = Object.assign(this.baseQuery, { 'brand_uom_xref_key': this.showByKey });
    // }

    // remove 'Null' string
    this.baseQuery = this.removeNullValues(this.baseQuery)

    // dublicate of this.baseQuery
    this.testing = this.baseQuery

    // Navigation Payload Data

    // debugger

    if (sessionStorage.getItem("payloadData") && queryParams?.['cardName']) {
      let pylodData: any = sessionStorage.getItem("payloadData");
      pylodData = JSON.parse(pylodData);
      if (Object.keys(pylodData).length > 0) {
        this.baseQuery = Object.assign(this.baseQuery, pylodData);
      }
    }

    // Main
    // debugger
    this.filterQuery.next(this.baseQuery);
      
      this.filterLoadFlg = false;
      // reset
      this.resetToDefault()
  }

  removeNullValues(obj: any) {
    for (let key in obj) {
      if (obj[key] === "null") {
        delete obj[key];
      }
    }
    return obj;
  }

  getEmpKey() {
    let emp: any = sessionStorage.getItem("employee_key");
    let employee_key = JSON.parse(emp)
    this.employKey = employee_key?._value || ''
    return employee_key?._value || null
  }

  // Reset Default Functionallity
  resetToDefault() {
    let resetFilterLength = ''
    this.badges.forEach((res: any) => {

      if (res.length == 'singleDateSelection') {
        const allData: any = sessionStorage.getItem('date-single-default-load');
        const selectData: any = sessionStorage.getItem('userStateSingleDate');
        if (selectData) {
          const serializedDataAll: any = JSON.parse(allData);
          const serializedDataSelect: any = JSON.parse(selectData);
          if (serializedDataSelect.time_date != serializedDataAll.time_date) {
            resetFilterLength = '1'
          }
        }
      } else if (res.path.includes('multi')) {
        let allMulti: any = Object.entries(this.allOptionData.multi).map(([key, value]) => ({ key, value }));
        let selectMulti: any = Object.entries(this.selectedItem.multi).map(([key, value]) => ({ key, value }));

        for (let i = 0; i < allMulti.length; i++) {
          if (allMulti[i].key.toLowerCase() == res.key.toLowerCase()) {
            let something = selectMulti.find((d: any) => d.key.toLowerCase() == allMulti[i].key.toLowerCase())
            if (something) {
              if (something.value.length != allMulti[i].value.length) {
                resetFilterLength += i
              }
            }
          }
        }
      } else if (res.path.includes('single')) {
        let allSingle: any = Object.entries(this.allOptionData.single).map(([key, value]) => ({ key, value }));
        let selectSingle: any = Object.entries(this.selectedItem.single).map(([key, value]) => ({ key, value }));

        for (let i = 0; i < allSingle.length; i++) {
          if (allSingle[i].key.toLowerCase() == res.key.toLowerCase()) {
            let something = selectSingle.find((d: any) => d.key.toLowerCase() == allSingle[i].key.toLowerCase())
            if (something) {
              if (something.value[something.key] != allSingle[i].value[allSingle[i].key]) {
                resetFilterLength += i
              }
            }
          }
        }
      } else if (res.path.includes('date')) {

        let allDate: any = Object.entries(this.allOptionData?.date).map(([key, value]) => ({ key, value }));
        let selectDate: any = Object.entries(this.selectedItem?.date).map(([key, value]) => ({ key, value }));

        if (allDate[0].value.start != selectDate[0].value.start || allDate[0].value.end != selectDate[0].value.end) {
          resetFilterLength = '1'
        }
      } else if (res.path.includes('timeCycle')) {


        if (!res.selectedType) {

          const allData: any = sessionStorage.getItem('timeWithoutQuarterFromAndToDefault');
          const selectData: any = sessionStorage.getItem('timeWithoutQuarterFromAndToSelected');

          if (selectData) {
            const serializedDataAll: any = JSON.parse(allData);
            const serializedDataSelect: any = JSON.parse(selectData);

            if (serializedDataSelect) {
              if (moment(serializedDataSelect.from_date).format('MM-DD-YYYY') != moment(serializedDataAll.from_date).format('MM-DD-YYYY') ||
                moment(serializedDataSelect.to_date).format('MM-DD-YYYY') != moment(serializedDataAll.to_date).format('MM-DD-YYYY')) {
                resetFilterLength = '1'
              }
            }

          }

        } else {

          const allData: any = sessionStorage.getItem('timeQuarterFromAndToDefault');
          const selectData: any = sessionStorage.getItem('timeQuarterFromAndToSelected');

          if (selectData) {
            const serializedDataAll: any = JSON.parse(allData);
            const serializedDataSelect: any = JSON.parse(selectData);

            if (serializedDataSelect) {
              if (serializedDataSelect.from_date != serializedDataAll.from_date || serializedDataAll.to_date != serializedDataSelect.to_date) {
                resetFilterLength = '1'
              }
            }

          }
        }

      } else if (res.path.includes('timeSingle')) {


        if (!res.selectedType) {

          const allData: any = sessionStorage.getItem('singleTimeWithoutQuarterFromAndToDefault');
          const selectData: any = sessionStorage.getItem('singleTimeWithoutQuarterFromAndToSelected');

          if (selectData) {
            const serializedDataAll: any = JSON.parse(allData);
            const serializedDataSelect: any = JSON.parse(selectData);

            if (serializedDataSelect) {
              if (moment(serializedDataSelect.from_date).format('MM-DD-YYYY') != moment(serializedDataAll.from_date).format('MM-DD-YYYY') ||
                moment(serializedDataSelect.to_date).format('MM-DD-YYYY') != moment(serializedDataAll.to_date).format('MM-DD-YYYY')) {
                resetFilterLength = '1'
              }
            }

          }

        } else {

          const allData: any = sessionStorage.getItem('singleTimeQuarterFromAndToDefault');
          const selectData: any = sessionStorage.getItem('singleTimeQuarterFromAndToSelected');

          if (selectData) {
            const serializedDataAll: any = JSON.parse(allData);
            const serializedDataSelect: any = JSON.parse(selectData);

            if (serializedDataSelect) {
              if (serializedDataSelect.from_date != serializedDataAll.from_date || serializedDataAll.to_date != serializedDataSelect.to_date) {
                resetFilterLength = '1'
              }
            }

          }
        }

      } else if (res.path.includes('Range')) {

        const data: any = sessionStorage.getItem('quarterFromAndTo');
        if (data) {
          const serializedData: any = JSON.parse(data);

          let allDate2: any = Object.entries(serializedData[1].quarterTo).map(([key, value]) => ({ key, value }));
          let selectDate2: any = Object.entries(this.selectedItem.quarterTo).map(([key, value]) => ({ key, value }));

          if (selectDate2?.[0]?.value) {
            let ab = allDate2?.[0]?.value?.['quarter,year'].replace(/\s+/g, "")
            let cb = selectDate2?.[0]?.value?.['quarter,year'].replace(/\s+/g, "")
            if (ab != cb) {
              resetFilterLength = '1'
            }
          }

          let allDateFrom: any = Object.entries(serializedData[0].quarterFrom).map(([key, value]) => ({ key, value }));
          let selectDateFrom: any = Object.entries(this.selectedItem.quarterFrom).map(([key, value]) => ({ key, value }));

          if (selectDateFrom?.[0]?.value) {
            let ab = allDateFrom?.[0]?.value?.['quarter,year'].replace(/\s+/g, "")
            let cb = selectDateFrom?.[0]?.value?.['quarter,year'].replace(/\s+/g, "")
            if (ab != cb) {
              resetFilterLength = '1'
            }
          }

        }



      } else if (res.path.includes('quarter')) {

        const data: any = sessionStorage.getItem('quarterFromAll');
        if (data) {
          const serializedData: any = JSON.parse(data);

          let defDate = serializedData?.quarterFrom?.quarter_select?.['quarter,year']
          let selectDate: any = Object.entries(this.selectedItem.quarter).map(([key, value]) => ({ key, value }));

          if (selectDate.length) {
            if (defDate != selectDate?.[0]?.value?.['quarter,year']) {
              resetFilterLength = '1'
            }
          }
        }
      }
    })

    if (resetFilterLength != '') {
      this.badgeCheck = true
    } else {
      this.badgeCheck = false
    }
  }

  // convert to stringify
  getFilter(data: any[], paramName: any) {
    if (data.length == 0) return null;
    let filter = "";
    data.map((e) => {
      filter += e[paramName] + ",";
    });
    return filter.slice(0, filter.lastIndexOf(","));
  }

  // All Execution 
  executeQuery(obj: any) {
    if (!this.baseQuery) return new Observable((ob) => ob.next(undefined));
    let query = Object.assign({}, this.baseQuery, obj)
    query["report_typ"] = this.report_type;
    // query['report_key']=undefined
    return this.apiService.post("kpi/get-execution-details", query);
  }
  dragexecuteQuery(obj: any) {
    if (!this.baseQuery) return new Observable((ob) => ob.next(undefined));
    let query = Object.assign({}, this.baseQuery, obj)
    query['report_key'] = query.kpi_key
    return this.apiService.post("kpi/get-execution-details", query);
  }

  // Heat Map Card Execution
  kpiBreakdownQuery(obj: any) {
    if (!this.baseQuery) return new Observable((ob) => ob.next(undefined));
    let query = Object.assign({}, this.baseQuery, obj)
    sessionStorage.setItem("kpiBaseQuery", JSON.stringify(query))
    // query['report_key']=undefined
    return this.apiService.post("kpi/get-execution-details", query);
  }

  // Control Chart Execution
  kpiBreakdownexecuteQuery(api_key: string,) {
    if (!this.baseQuery) return new Observable((ob) => ob.next(undefined));
    let query = Object.assign({}, this.baseQuery)
    query["api_key"] = api_key;
    query["report_typ"] = 'D';
    query["time_cycle"] = this.report_type;
    query['report_key'] = undefined

    return this.apiService.post("kpi/get-execution-details", query);
  }

  // Threshold Execution
  thresholdsexecuteQuery(obj: any) {
    if (!this.baseQuery) return new Observable((ob) => ob.next(undefined));
    let query = Object.assign({}, this.baseQuery, obj)

    query["report_typ"] = 'I';
    query["time_cycle"] = this.report_type;
    query['report_key'] = undefined

    return this.apiService.post("kpi/get-execution-details", query);
  }

  // KPI Breakdown Popup Execution
  kpiBreakdownexecuteQuerypopup(api_key: string,) {
    if (!this.baseQuery) return new Observable((ob) => ob.next(undefined));

    let kpiquery: any = sessionStorage.getItem("kpiBaseQuery")
    kpiquery = JSON.parse(kpiquery)

    let query: any = Object.assign({}, this.baseQuery)
    let queryData: any = {
      "brand_key": this.baseQuery["brand_key"],
      "source_nm": kpiquery["source_nm"],
      "client_key": 2001,
      "api_key": api_key,
      "source_type": "HUB,SP",
      "source_key": "3001,3002,3003,3004,2005",
      "kpi_key": "200101,200102,200103,200104,200105,200106,200107,200108,200109,200110,200111,200112,200113,200114,200115,200116,200117,200118,200119,200120,200124,200125,200126,200127,200128,999999",
      "report_typ": "D",
      "time_cycle": this.report_type,

    }
    // query['report_key']=undefined
    if (!this.hasMethodExecuted) {
      // Your code to be executed only once
      queryData["from_date"] = kpiquery["from_date"],
        queryData["to_date"] = kpiquery["to_date"]

      // Set the flag to true to ensure it doesn't execute again
      this.hasMethodExecuted = true;
    } else {

      if (this.report_type == 'W') {
        queryData["from_date"] = moment(query["to_date"]).subtract(6, 'd').toDate();
        queryData["from_date"] = moment(queryData["from_date"]).format('yyyy-MM-DD')
        queryData["to_date"] = query["to_date"]//this.kpiDateRange[0].to_dt
      } else if (this.report_type == 'M') {
        queryData["from_date"] = moment(query["to_date"]).startOf('month').toDate()
        queryData["from_date"] = moment(queryData["from_date"]).format('yyyy-MM-DD')
        queryData["to_date"] = query["to_date"]//this.kpiDateRange[0].to_dt
      } else if (this.report_type == 'Q') {
        let findDate = this.getQuarter(query["to_date"])
        queryData["from_date"] = findDate?.from_date
        queryData["from_date"] = moment(queryData["from_date"]).format('yyyy-MM-DD')
        queryData["to_date"] = findDate?.to_date
      } else if (this.report_type == 'Y') {
        queryData["from_date"] = moment(query["to_date"]).startOf('year').toDate()
        queryData["from_date"] = moment(queryData["from_date"]).format('yyyy-MM-DD')
        queryData["to_date"] = query["to_date"]//this.kpiDateRange[0].to_dt
      }
    }

    return this.apiService.post("kpi/get-execution-details", queryData);
  }

  // get a date for KPI Breakdown Popup Execution 
  getQuarter(date: any) {

    let year = moment(date).year()
    let month = moment(date).month()

    let toOption: any = [
      { value: 'Q1', months: ['Jan', 'Feb', 'Mar'], "monthFound": '1,2,3' },
      { value: 'Q2', months: ['Apr', 'May', 'Jun'], "monthFound": '4,5,6' },
      { value: 'Q3', months: ['Jul', 'Aug', 'Sep'], "monthFound": '7,8,9' },
      { value: 'Q4', months: ['Oct', 'Nov', 'Dec'], "monthFound": '10,11,12' }
    ];

    toOption.forEach((res: any) => {
      if (res.value == 'Q1') {
        Object.assign(res,
          { "from_date": year + '-' + '01' + '-' + '01' },
          { "to_date": year + '-' + '03' + '-' + '31' }, { "year": year })
      } else if (res.value == 'Q2') {
        Object.assign(res,
          { "from_date": year + '-' + '04' + '-' + '01' },
          { "to_date": year + '-' + '06' + '-' + '30' }, { "year": year })
      } else if (res.value == 'Q3') {
        Object.assign(res,
          { "from_date": year + '-' + '07' + '-' + '01' },
          { "to_date": year + '-' + '09' + '-' + '30' }, { "year": year })
      } else if (res.value == 'Q4') {
        Object.assign(res,
          { "from_date": year + '-' + '10' + '-' + '01' },
          { "to_date": year + '-' + '12' + '-' + '31' }, { "year": year })
      }
    })

    return toOption.find((o: any) => o.monthFound.includes(month))
  }

  // getTimecycledata(postData: any, dashboardKey: any) {
  //   let postvalue: any = {
  //     "brand_key": postData["brand_key"] || 200104,
  //     "dashboard_key": dashboardKey,
  //     "client_key": this.client_key,
  //     "report_typ": postData["report_typ"] || "Q",
  //     "employee_key": this.employKey
  //   }

  //   return this.apiService.post("kpi/get-timecycle-date", postvalue);
  // }

  // FRM Portal Execution
  frmExecutionQuery(obj: any) {
    if (!this.baseQuery) return new Observable((ob) => ob.next(undefined));
    let query = Object.assign({}, this.baseQuery, obj)

    return this.apiService.post("kpi/get-execution-details", query);
  }

  // FRM Portal Update Status List Execution
  frmUpdateList(obj: any) {
    if (!this.baseQuery) {
      let baseQuery: any = sessionStorage.getItem("baseQueryPatientStatusChange");
      this.baseQuery = baseQuery ? JSON.parse(baseQuery) : {}
    }

    let query = Object.assign({}, this.baseQuery, obj)
    return this.apiService.post("kpi/get-execution-details", query);
  }

  // FRM Portal Alert Remove and Update Execution
  frmRemoveAlerts(obj: any) {
    let query = Object.assign({}, this.baseQuery, obj)
    return this.apiService.post("kpi/get-execution-details", query);
  }

  // FRM Module - Update Patient Status
  updateStatus(query: any) {
    if (!this.baseQuery) return new Observable((ob) => ob.next(undefined));
    let data = Object.assign({}, this.baseQuery, query)
    return this.apiService.post("kpi/get-execution-details", data);
  }

  // Bullet Chart and some others Execution
  executePatientQuery(obj: any) {
    var empkey: any = sessionStorage.getItem("employee_key");
    let employee: any = JSON.parse(empkey);
    this.employKey = employee?._value
    if (!this.baseQuery) return new Observable((ob) => ob.next(undefined));
    let query = Object.assign({}, this.baseQuery, obj)
    query["source_key"] = this.source_key || undefined

    return this.apiService.post("kpi/get-execution-details", query);
  }
  executebulletQuery(obj: any) {
    if (!this.baseQuery) return new Observable((ob) => ob.next(undefined));
    let query = Object.assign({}, this.baseQuery, obj)

    return this.apiService.post("kpi/get-execution-details", query);
  }

  // IR Execution
  IRexecuteQuery(obj: any) {
    if (!this.baseQuery) return new Observable((ob) => ob.next(undefined));
    let query = Object.assign({}, this.baseQuery, obj)
    return this.apiService.post("kpi/get-execution-details", query);
  }

  // Comment Api
  commentsQuery(postdata: any) {
    // console.log(postdata)
    if (!this.baseQuery) return new Observable((ob) => ob.next(undefined));

    let query = Object.assign({}, this.baseQuery, postdata)
    //  make undefined which is not value
    let modelKey = Object.keys(query);
    modelKey.forEach((key: any) => {
      if (!query.hasOwnProperty(key)) {
        this.model[key] = undefined
      }
    });
    query['report_key'] = undefined

    return this.apiService.post("kpi/get-execution-details", query);
  }

  // Comment Api
  alertNotificationData(postdata: any) {
    // console.log(postdata)
    if (!this.baseQuery) return new Observable((ob) => ob.next(undefined));
    let query = Object.assign({}, this.baseQuery, postdata)

    return this.apiService.post("kpi/get-execution-details", query);
  }

  // Comment Api
  alertNotificationFilter(postdata: any) {
    return this.apiService.post("kpi/get-execution-details", postdata);
  }

  // get Json Data From Local
  getSampleJsonData(fileName: any) {
    return this.apiService.getDoc(fileName)
  }

  // Show By UOM 
  executeQueryForShowBy(query: any) {
    return this.apiService.post("kpi/get-uom-data", query);
  }
  loadshowByApi(obj?: any) {
    if (!this.baseQuery) return new Observable((ob) => ob.next(undefined));
    let query = Object.assign({}, this.baseQuery, obj);
    return this.apiService.post("/kpi/get-ir-showby-filter", query);
  }

  changeFilters(title: any) {
    setTimeout(() => {
      this.emitFilterChange.next(title);
    }, 2000);
  }

  // Time Cycle Execution
  selfTimeCycleExecuteQuery(obj: any) {
    if (!this.baseQuery) return new Observable((ob) => ob.next(undefined));
    let query = Object.assign({}, this.baseQuery, obj);
    return this.apiService.post("kpi/get-execution-details", query);
  }

  // Reset Funtionality to remove all session stored
  resetFilter() {

    // reset the filter based on carry to next screens 
    this.resetFilterAndPayloadInNavDetails();

    // netWork Status Refresh
    sessionStorage.removeItem('net_status_flr')

    // sessionStorage.removeItem('selectedKPIcontrol')
    sessionStorage.removeItem('singleTimeCycleQuarter')
    
    // single date selection
    sessionStorage.removeItem('userStateSingleDate');
    
    // sessionStorage.removeItem('recdetailGrid');
    // sessionStorage.removeItem("netWorkPayloads");

    // User State for session stored
    let resFil: any = sessionStorage.getItem("userState");
    let reset: any = JSON.parse(resFil)

    if (reset) {
      if (reset.selectedFilters) {
        reset.selectedFilters = {}
        const serializedData: any = JSON.stringify(reset);
        sessionStorage.setItem("userState", serializedData);
      }
    }

    // Time cycle filter user state carring
    this.resetForBrandBased();

    // Time cycle Single filter user state carring
    let resFilTimeSingle: any = sessionStorage.getItem("userStateTimeSingle");
    let resetTimeSingle: any = JSON.parse(resFilTimeSingle)

    if (resetTimeSingle) {
      if (resetTimeSingle.selectedFilters) {
        resetTimeSingle.selectedFilters = {}
        const serializedDataTime: any = JSON.stringify(resetTimeSingle);
        sessionStorage.setItem("userStateTimeSingle", serializedDataTime);
        sessionStorage.removeItem('singleTimeQuarterFromAndToSelected');
        sessionStorage.removeItem('singleTimeWithoutQuarterFromAndToSelected');

        sessionStorage.removeItem('singleTimeWithoutQuarterFromAndToDefault');
        sessionStorage.removeItem('singleTimeQuarterFromAndToDefault');
      }
    }

    // User State for session stored
    this.resetForKpiSingle();
    this.resetForBrandBasedSingle();

    //  delete model values for filter
    let modelKey = Object.keys(this.model);
    modelKey.forEach((key: any) => {
      if (this.model.hasOwnProperty(key)) {
        this.model[key] = ''
      }
    })

    if (!(Object.keys(this.filterReset).length === 0)) {
      this.filterReset.resetModel()
    }

    this.formReset.next(true)
    this.badgeCheck = false
    this.filterCheck = true
  }

  resetFilterAndPayloadInNavDetails() {
    let navPag: any = sessionStorage.getItem('routerNavDetailPages');
    sessionStorage.removeItem('filterData');

    if (navPag) {
      navPag = JSON.parse(navPag);

      if (navPag.length > 0) {
        navPag.map((m: any) => {
          if (m.filter) delete m.filter
          if (m.payload) delete m.payload
        })

        sessionStorage.setItem('routerNavDetailPages', JSON.stringify(navPag));
      }
    }
  }

  resetForKpiSingle() {
    // User State for session stored
    let resFilterKPI: any = sessionStorage.getItem("userStateKpiSingle");
    let resetKPISingle: any = JSON.parse(resFilterKPI)

    if (resetKPISingle) {
      if (resetKPISingle.selectedFilters) {
        resetKPISingle.selectedFilters = {}
        const serializedData: any = JSON.stringify(resetKPISingle);
        sessionStorage.setItem("userStateKpiSingle", serializedData);
      }
    }
  }

  resetForBrandBased() {
    // Time cycle filter user state carring
    let resFilTime: any = sessionStorage.getItem("userStateTime");
    let resetTime: any = JSON.parse(resFilTime)

    if (resetTime) {
      if (resetTime.selectedFilters) {
        resetTime.selectedFilters = {}
        const serializedDataTime: any = JSON.stringify(resetTime);
        sessionStorage.setItem("userStateTime", serializedDataTime);
        sessionStorage.removeItem('timeQuarterFromAndToSelected');
        sessionStorage.removeItem('timeWithoutQuarterFromAndToSelected');

        sessionStorage.removeItem('timeWithoutQuarterFromAndToDefault');
        sessionStorage.removeItem('timeQuarterFromAndToDefault');
      }
    }
  }

  resetForBrandBasedSingle() {

    // Time cycle Single filter user state carring
    let resFilTimeSingle: any = sessionStorage.getItem("userStateTimeSingle");
    let resetTimeSingle: any = JSON.parse(resFilTimeSingle)

    if (resetTimeSingle) {
      if (resetTimeSingle.selectedFilters) {
        resetTimeSingle.selectedFilters = {}
        const serializedDataTime: any = JSON.stringify(resetTimeSingle);
        sessionStorage.setItem("userStateTimeSingle", serializedDataTime);
        sessionStorage.removeItem('singleTimeQuarterFromAndToSelected');
        sessionStorage.removeItem('singleTimeWithoutQuarterFromAndToSelected');

        sessionStorage.removeItem('singleTimeWithoutQuarterFromAndToDefault');
        sessionStorage.removeItem('singleTimeQuarterFromAndToDefault');
      }
    }
  }

  kpidateRange(timeCycle: any, endDate: any) {
    let fromDate: any
    if (timeCycle == 'W') {
      fromDate = moment(endDate).subtract(6, 'd').toDate();
      endDate = endDate
    } else if (timeCycle == 'M') {
      fromDate = moment(endDate).startOf('month').toDate()
      endDate = endDate
    } else if (timeCycle == 'Q') {
      let findDate = this.getQuarter(endDate)
      fromDate = findDate?.from_date
      endDate = findDate?.to_date
    } else if (timeCycle == 'Y') {
      fromDate = moment(endDate).startOf('year').toDate()
      endDate = endDate
    }
    let kpiquery: any = sessionStorage.getItem("kpiBaseQuery")
    kpiquery = JSON.parse(kpiquery)
    if (!this.hasMethodExecuted) {

      return moment(kpiquery['from_date']).format('MMM DD, yyyy') + " - " + moment(kpiquery['to_date']).format('MMM DD, yyyy')
      // Set the flag to true to ensure it doesn't execute again
      this.hasMethodExecuted = true;
    } else {

      return moment(fromDate).format('MMM DD, yyyy') + " - " + moment(endDate).format('MMM DD, yyyy')
    }

  }

  filterApplyBtn() {
    // window.colState = '';
    // sessionStorage.removeItem('window.colState');
    // this.userActivityService.hierarchicalGridState
  }

  isEmptyObject(obj: any) {
    return Object.keys(obj).length === 0;
  }
}
