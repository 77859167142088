import * as _ from 'lodash'
import * as d3 from 'd3'

const timeParser = d3.timeParse('%Y-%m-%d')
const timeFormatter = d3.timeFormat('%d %b')
const timeFormatter2 = d3.timeFormat('%a')

export default {
  title: 'Rezurock 2w',
  data: {
    type: 'rezurock',
    url: 'data/status-overview/rezurock/data.json',
  },
  legend: {
    id: 'data-load-status-legend',
    data: {
      source: 'service',
    },
    view: {
      editable: true,
    },
  },
  statusOverview: {
    id: 'data-load-status-overview',
    data: {
      type: 'series'
    },
    view: {
      aggregate4Ancestors: true,
      colorScheme: ['#D11044', '#645DD7', '#3AC97C'],
      itemAccessors: ['extract_type', 'file_type'],
      itemsOrder: {
        extract_type: [],
        file_type: ['SP Status', 'SP Dispense', 'SP Inventory', 'SP Patient Demo']
      },
      // itemsOrder: {
      //   // extract_type: ['SP', 'HUB', 'eRx', 'IQVIA', 'Copay'],
      //   // file_type: ['SP Status', 'SP Dispense', 'SP Inventory', 'SP Patient Demo']
      // },
      xAccessor: (record: any) => +timeParser(record.cycle_dt)!,
      xFormatter: (value: any) => timeFormatter(value),
      xFormatter2: (value: any) => timeFormatter2(value),
      statusAccessor: 'file_status',
      cellValueAccessor: (record: any, acc: number = 0) => acc + record.sent_records,
      cellValueFormatter: d3.format(','),
      detailsAccessor: (record: any, obj: any) => {
        if (!record.file_nm) return {}
        if (!record.report_key) return {}
        const index = (_.keys(obj).length + 1).toString()
        const file = obj[record.file_nm] || {
        index: `File ${_.padStart(index, 3, '0')}`,
        status: record.file_status ,
        file_type_key:record.file_type_key,
        file_type:record.file_type,
        source_key:record.source_key,
        sent_records_trend:record.sent_records_trend,
        on_hold_trend:record.on_hold_trend,
        period:record.cycle_dt,
        file_key:record.file_key,
        file_nm:record.file_nm,
        show_grid_flg:record.show_grid_flg,
        source_nm:record.source_name,
        source_name:record.source_name
      }

        obj[record.file_nm] = file
        file.count = {
          sent_records: (obj.sent_records || 0) + Number(record.sent_records),
          failures: (obj.failures || 0) + Number(record.failures),
          errors: (obj.errors || 0) + Number(record.errors),
          on_hold: (obj.on_hold || 0) + Number(record.on_hold),
        }
        return obj
      },
      badgeAccessor: function (serie: any) {
        return !!(serie.failures || serie.errors || serie.on_hold || serie.comment_flg=='Y')
      },
      delayBadgeAccessor: function (serie: any) {
        return !!(serie.delay_ind =='Y')
      },
      borderBadgeAccessor: function (serie: any) {
        return !!(serie.interim_extract_flg =='Y')
      },
      selectable: true,
      legend: 'data-load-status-legend',
      tree: 'data-load-status-tree',
      tooltip: 'data-load-status-tooltip',
    }
  },
  tree: {
    id: 'data-load-status-tree',
    type: 'Tree',
    data: {
      source: 'service',
    },
    view: {
      collapsedAll: true,
    }
  },
  tooltip: {
    id: 'data-load-status-tooltip',
    data: {
      source: 'service',
    },
    view: {
      template: 'custom',
    }
  }
}