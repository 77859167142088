<!-- Resize  -->
<div 
    class="resize resize__left" 
    (resize)="onResizeSide($event)" 
    [targetElement]="offcanvasRightAlertView"
    [direction]="AngularResizeElementDirection.LEFT">
</div>

<!-- Header Name -->
<h2 class="px-2 pt-2 mb-0 selectedHeaderName">Alert</h2>

<hr>

<div class="m-2" style="width: 300px;text-align: right;">
    <input type="search" class="form-control" id="exampleInputSearch1" [(ngModel)]="searchAlertText" aria-describedby="searchHelp" placeholder="Search Alerts" style="font-size: 14px;font-family: 'Poppins';">
</div>

<hr>

<div *ngIf="(alert_notification | searchText: searchAlertText)?.length > 0; else noResults">
    <cdk-virtual-scroll-viewport  class="scrollForAnnotation" itemSize="10" style="overflow-y: auto;height: calc(100vh - 105px);font-family: 'Poppins' !important;margin: 4px 10px;">
      <ng-container *cdkVirtualFor="let alt of alert_notification | searchText: searchAlertText; let i = index;trackBy: trackByFnMessage;">
        <div class="d-flex my-3">
          <div class="mr-2">
            <button mat-fab class="alert_dot"></button>
          </div>

          <div>
            <span class="alert_header">
              <span>{{alt.alert_nm || 'No Name'}}</span>
              <span>{{alt.source_name ? ', ' + alt.source_name : ''}}</span>
              <span>{{alt.sp_location_name ? ', ' + alt.sp_location_name : ''}}</span>
              <!-- <b style="color: #1363df">></b>  -->
            </span> 
            
            <!-- {{alt | json }} -->
            <span class="alert_content">{{alt.alert_desc ||' '}}</span>
            <span class="alert_date">{{ alt.alert_dt ? moment(alt.alert_dt).format('MM.DD.YYYY') : '' }}</span>
          </div>
        </div>
        <hr>
      </ng-container>
    </cdk-virtual-scroll-viewport>
</div>


<ng-template #noResults>
    <div class="no-results" style="text-align: center;margin-top: 100px;">
        No data found.
    </div>
</ng-template>

<!-- Resize  -->
<div 
    class="resize resize__bottom--left" 
    (resize)="onResizeSide($event)" 
    [targetElement]="offcanvasRightAlertView"
    [direction]="AngularResizeElementDirection.BOTTOM_LEFT">
</div>